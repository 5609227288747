// Required for Prismic 
// Remember that the routes are available in the url field, not the uid field in the query

exports.linkResolver = (doc) => {
  
      if (doc.type === 'homepage') {
        return `/`
      }
      
      if (doc.type === 'what_we_trade_page') {
        return `/what-we-trade/${doc.uid}`
      }

      if (doc.type === 'about_us_page') {
        return `/about-us/${doc.uid}`
      }

      if (doc.type === 'page') {
        return `/${doc.uid}`
      }

      if (doc.type === 'alt_page') {
        return `/${doc.uid}`
      }
 
      return '/'
    }
    